import React from "react";
import { connect } from "react-redux";
import { compose, hoistStatics } from "recompose";
import withStyles from "@material-ui/core/styles/withStyles";
import { injectIntl } from "react-intl";
import camera from "../../assets/img/icons/camera.png";
import pdf from "../../assets/img/icons/pdf-placeholder.png";
import propertiesMessages from "../../../common/propertiesTypes/propertiesMessages";
import plus from "../../assets/img/icons/plus.png";
import buttonsStyle from "../../assets/jss/material-dashboard-pro-react/views/buttonsStyle.jsx";
import theme from "../../assets/css/theme";
import { resizeImages } from "./funcs.js";
import { startToast } from "../../../common/app/actions";
import Text from "../CementoComponents/Text";
import systemMessages from "../../../common/app/systemMessages";
import path from 'path';

class PictureUpload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fileSelected: null,
    };
    this.handleImageChange = this.handleImageChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onSubmit(filesDataObjectsArray) {
    const { onSubmit, multiple } = this.props;
    if (onSubmit && filesDataObjectsArray)
      onSubmit(multiple ? filesDataObjectsArray : filesDataObjectsArray[0]);
  }

  handleImageChange(e) {
    const {
      withResize,
      resizePercentFromDefaultResize,
      accept,
      intl,
      startToast,
      viewer,
    } = this.props;
    let extensions = (accept || ".png,.jpg,.jpeg").split(",");
    let validExtensionsMap = {};
    extensions.forEach((e) => (validExtensionsMap[e] = e));
    let allValid = true;
    let invalidAlertDidShow = false;
    try {
      e.preventDefault();
      let files = e.target.files;
      let filesCount = files.length;
      let toRet = [];
      files.loopEach((i, file) => {
        let reader = new FileReader();
        const name = file.name;
        const extension = path.extname(name) || '';
        const title = name.replace(extension, '');
        const extName = extension.replace('.', '').toLowerCase();
        allValid = allValid && Boolean(validExtensionsMap[`.${extName}`]);
        if (!allValid) {
          if (!invalidAlertDidShow) {
            invalidAlertDidShow = true;
            startToast({ title: systemMessages.unsupportedFile });
          }
          return;
        }

        const onImageUri = (imgUri) => {
          toRet.push({
            isLocal: true,
            data: imgUri,
            title,
            name,
            extension: extName,
            type: extName,
            owner: { displayName: viewer.displayName, id: viewer.id },
            uploadTS: Date.now(),
          });
          if (!this.state.fileSelected) this.setState({ fileSelected: true });
          if (toRet.length == filesCount) this.onSubmit(toRet);
        };

        reader.addEventListener(
          "load",
          ((event) => {
            let loaded = event.target;
            if (
              (withResize || resizePercentFromDefaultResize) &&
              (extName == "png" || extName == "jpg" || extName == "jpeg")
            )
              resizeImages(
                loaded.result,
                resizePercentFromDefaultResize || 70,
                onImageUri
              );
            else onImageUri(loaded.result);
          }).bind(this)
        );

        if (file) reader.readAsDataURL(file);
      });
    } catch (e) {
      console.log("PictureUpload fail", e);
    }
  }

  handleSubmit(e) {
    e.preventDefault();
    // this.state.file is the file/image uploaded
    // in this function you can save the image (this.state.file) on form submit
    // you have to call it yourself
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.newImageData != nextProps.newImageData)
      this.setState({ fileSelected: nextProps.newImageData });
  }

  render() {
    const { accept, multiple, mode, iconStyle = {} } = this.props;
    const { fileSelected } = this.state;

    let isAcceptingPdf = accept.includes('pdf');
    let isAcceptingVideo = accept.includes('mp4')

    let inputTitle = null;

    if (isAcceptingPdf) inputTitle = propertiesMessages.addFile;
    else if (isAcceptingVideo) inputTitle = propertiesMessages.addVideo;
    else inputTitle = propertiesMessages.addPicture;

    let iconSrc = mode == "min" ? plus : isAcceptingPdf ? pdf : camera

    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            cursor: "pointer",
            backgroundColor: "none",
            position: "relative",
            border: "none",
            color: theme.headerBarStyle,
            borderRadius: "none",
          }}
        >
          <img
            src={iconSrc}
            style={{ margin: 5, ...iconStyle }}
          />
          {Boolean(mode != "min") && (
            <Text>
             {inputTitle}
            </Text>
          )}
          <input
            style={{
              cursor: "pointer",
              position: "absolute",
              opacity: 0,
              width: "100%",
              bottom: "0px",
              top: "0px",
              overflow: "hidden",
            }}
            multiple={multiple}
            type="file"
            accept={accept || ".png,.jpg,.jpeg"}
            onChange={(e) => this.handleImageChange(e)}
          />
        </div>
      </div>
    );
  }
}
//{!Boolean(false) && <div style={{ alignSelf:'center' }}><ImageOutlined/></div>}
PictureUpload = withStyles(buttonsStyle)(PictureUpload);
PictureUpload = injectIntl(PictureUpload);
const enhance = compose(
  connect(
    (state) => ({
      viewer: state.users.viewer,
    }),
    { startToast }
  )
);
export default enhance(PictureUpload);
