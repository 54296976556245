import React from 'react';
import ObjectPropertiesPageUI from './ObjectPropertiesPageUI/ObjectPropertiesPageUI';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { connectContext } from 'react-connect-context';
import { lokiInstance} from '../../../common/configureMiddleware';
import { getLocationTitle } from '../Locations/funcs';
import withStyles from '@material-ui/core/styles/withStyles';
import { deleteEmployee, upsertEmployee } from '../../../common/employees/actions';
import { deleteEquipment, upsertEquipment } from '../../../common/equipment/actions';
import { ProjectContext } from '../../../common/projects/contexts';
import { uploadPropertiesInstances } from '../../../common/propertiesInstances/actions';
import Text from '../../components/CementoComponents/Text';
import systemMessages from '../../../common/app/systemMessages';
import theme from '../../assets/css/theme';
import propertiesMessages from '../../../common/propertiesTypes/propertiesMessages';
import { exportFormPDF } from '../../../common/pdf/actions';
import moment from 'moment';
import { startLoading, hideLoading, startToast } from '../../../common/app/actions';
import * as propertyTypes from '../../../common/propertiesTypes/propertiesTypes';
import _ from 'lodash';
import { funcSingleton } from "../../../common/app/funcs";
import { deleteForm, upsertForm } from "../../../common/forms/actions";
import reportsMessages from "../../../common/reports/reportsMessages";
import siteControlMessages from "../../../common/siteControl/siteControlMessages";
import { getFormStatusParams } from "../../../common/forms/formsStatusStates";
import { isSiteControlProject, getEmployeesPresence } from "../../../common/siteControl/funcs";
import EmployeesPresenceLogs from "../../../common/app/components/EmployeesPresenceLogs";
import { track } from '../../../common/lib/reporting/actions';
import { safeToJS } from '../../../common/permissions/funcs';
import withRouterHOC from '../../components/Router/util/withRouterHOC';
import { getNewId } from '../../../common/lib/api';

const POSTS_OPENED_FROM_REPORT = 'openedFromReport';
const POSTS_USER_SELECTED = 'userSelectedIssues';

/**
 * @typedef SectionProperty
 * @property {string} id
 * @property {string | undefined} title
 * @property {string} type
 * @property {{}[]} extraTypes
 * @property {{}} prop
 * @property {{}} settings
 * @property {string} businessType
 * @property {string} universalId
 * @property {number} ordinalNo
 * @property {boolean} isPrimary
 *
 * @typedef Section
 * @property {string} id
 * @property {string} [title]
 * @property {number} ordinalNo
 * @property {boolean} isCertificationSection
 * @property {boolean} isSupportedCertBehaviour
 * @property {boolean} isRoleAppointmentSection
 * @property {SectionProperty[]} properties,
 *
 */
class ObjectPropertiesPage extends React.Component {
  constructor(props) {
    super(props);
    this.setComponentData = this.setComponentData.bind(this);
    this.getRelevantInstances = this.getRelevantInstances.bind(this);
    this.saveChanges = this.saveChanges.bind(this);
    this.addObject = this.addObject.bind(this);
    this.deleteObject = this.deleteObject.bind(this);
    this.lokiPropertyInstancesListener = this.lokiPropertyInstancesListener.bind(this);
    this.getPropertyString = this.getPropertyString.bind(this);
    this.handleGetEmployeesPresence = this.handleGetEmployeesPresence.bind(this);

    this.state = {
      instancesByPropertyMap:{},
      currLocationTitle: {},
      objectPropsPageUIProps: {},
    };
  }


initObjectState = (newObject, props, skipSetState = false) => {
    const { subjectType } = props;

    let newStateChanges = {};

    if (newObject && subjectType === 'forms') {
      newStateChanges.createdPosts = {};
      newStateChanges.selectedPosts = {};
      [POSTS_OPENED_FROM_REPORT, POSTS_USER_SELECTED].forEach(postsCollectionName =>
        Object.entries(_.get(newObject, ['posts', postsCollectionName], {})).forEach(([postId, isActive]) => 
          postId && isActive ? _.set(newStateChanges, [postsCollectionName === POSTS_OPENED_FROM_REPORT ? 'createdPosts' : 'selectedPosts', postId], postId) : null
        )
      );
    }

    if (!skipSetState && Object.keys(newStateChanges).length)
      this.setState(newStateChanges);

    return newStateChanges;
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.object !== this.state.object)
      this.initObjectState(this.state.object, this.props);
    
    if (prevProps.propertiesTypes != this.props.propertiesTypes ||
      prevProps.primaryPropId != this.props.primaryPropId ||
      prevProps.subjectName != this.props.subjectName ||
      prevState.currLocationTitle != this.state.currLocationTitle ||
      prevState.instancesByPropertyMap != this.state.instancesByPropertyMap) {
        this.handleGetEmployeesPresence();
    }

    this.handleRecalcObjectPropertiesPageUIProps(prevProps, prevState);
  }

  lokiPropertyInstancesListener(collectionName) {
    if (collectionName == 'propertyInstances') {
      let newStateChanges = {};
      let results = this.getRelevantInstances();
      newStateChanges.objectGroupId = results.objectGroupId;
      newStateChanges.instancesByPropertyMap = results.instancesByPropertyMap;
      newStateChanges.primaryValue = results.primaryValue;
      this.setState(newStateChanges);
    }
  }

  lokiObjectsListener = (collectionName) => {
    if (collectionName !== this.props.subjectType)
      return;

    let newStateChanges = {};
    
    const object = this.getLokiObject(this.props.objectId);
    if (object)
      newStateChanges.object = object;

    if (Object.keys(newStateChanges).length)
      this.setState(newStateChanges);
  }

  getLokiObject = (objectId) => {
    return ((this.lokiObjectsRef && this.lokiObjectsRef.cementoFind({ id: objectId })) || [])[0] || null;
  }

  componentWillMount() {
    this.lokiPropertyInstances = lokiInstance.getCollection('propertyInstances');
    this.lokiPropertyInstances.cementoOn('objectPropertiesPageInstancesListener', this.lokiPropertyInstancesListener);
    this.setComponentData({}, this.props);
    this.handleRecalcObjectPropertiesPageUIProps({}, {});
  }

  componentWillUnmount() {
    this.lokiPropertyInstances.cementoOff('objectPropertiesPageInstancesListener');
    if (this.clearLokiObjectsListener) this.clearLokiObjectsListener();
  }

  componentWillReceiveProps(nextProps) {
    this.setComponentData(this.props, nextProps)
  }

  componentDidMount() {
    const { track, selectedProjectId, subjectType, objectId } = this.props;
    track('enterObjectPropertiesPage', { projectId: selectedProjectId, subjectType, objectId })

    this.handleGetEmployeesPresence();
    this.handleRecalcObjectPropertiesPageUIProps({}, {});
    
  }

  setComponentData(props, nextProps) {      
    let { propertiesTypes, onGroupsFound, subjectName } = nextProps || props;
    let newStateChanges = {};

    if (props.subjectType !== nextProps.subjectType) {
      if (this.clearLokiObjectsListener)
        this.clearLokiObjectsListener();

      this.lokiObjectsRef = lokiInstance.getCollection(nextProps.subjectType);
      if (this.lokiObjectsRef) {
        const listenerId = 'objectsPropertiesPageLokiObjectsListener';
        this.lokiObjectsRef.cementoOn(listenerId, this.lokiObjectsListener);
        this.clearLokiObjectsListener = () => this.lokiObjectsRef.cementoOff(listenerId);
      }
    }

    const relevantInstances = this.getRelevantInstances(nextProps);
    const didObjectInstancesChanged = !_.isEqual(relevantInstances.instancesByPropertyMap, this.state.instancesByPropertyMap);
    if (props.selectedProjectId != nextProps.selectedProjectId || props.objectId != nextProps.objectId || didObjectInstancesChanged) {
      newStateChanges.objectGroupId = relevantInstances.objectGroupId;
      newStateChanges.primaryValue = relevantInstances.primaryValue;
      newStateChanges.instancesByPropertyMap = relevantInstances.instancesByPropertyMap;
    }

    if (props.subjectName !== subjectName || this.props.isValDiff(nextProps, ['propertiesTypes', subjectName, 'groups'])) {
      onGroupsFound?.(Boolean(propertiesTypes.getNested([subjectName, 'groups'])));
    }

    if (props.subGroupId != nextProps.subGroupId) {
      newStateChanges.subGroupId = nextProps.subGroupId
    }
    if (props.objectId != nextProps.objectId) {
      let groupsProp = nextProps.getNested(['propertiesTypes', nextProps.subjectName, 'groups'], {});
      if (nextProps.createObjectMode) {
        newStateChanges.objectGroupId = null;
        if (nextProps.initialGroupId) {
          newStateChanges.objectGroupId = nextProps.initialGroupId;
        }
        else if (groupsProp.id == 'groups' && groupsProp.values.length == 1) {
          let firstId = groupsProp.values[0].id
          newStateChanges.objectGroupId = firstId;
        }
      }
      else {
        const object = this.getLokiObject(nextProps.objectId);
        newStateChanges.object = object;
        Object.assign(newStateChanges, this.initObjectState(object, nextProps, true));
      }
    }

    if (nextProps.subjectName == 'locationsInfo' && 
        (props.objectId != nextProps.objectId || 
         props.buildings != nextProps.buildings || 
         props.floors != nextProps.floors || 
        props.units != nextProps.units)) {
      newStateChanges.currLocationTitle = getLocationTitle(
        nextProps.buildings, 
        nextProps.floors, 
        nextProps.units, 
        null, 
        null, 
        null, 
        nextProps.objectId, 
       nextProps.intl);
    }

    if (Object.keys(newStateChanges).length > 0)
      this.setState(newStateChanges);
  }

  calcObjectPropertiesPageUIProps = (inProps, inState) => {
    const {
      selectedProjectId,
			subjectType,
			subjectName,
			primaryPropId,
			propertiesTypes,
			mode,
			intl,
			createObjectMode,
			useMenusScrollsBar,
			propertiesMappings,
			propertiesSections,
			onCardLoad,
			onInnerObjectSelect,
			onEditModeChange,
			onDisableChange,
			onCancel,
      onPrint,
			filteredProperties,
			useCollapsibleSections,
			popInnerCardQueue,
			setHeaderParams,
			tableMethods,
			setCardHeaderParams,
			editMode,
      objectExtraInfo: { formType } = {},
      onObjectCreate,
      onObjectDelete,
      initialGroupId,
      initialSubGroupId,
      useSections,
      scrollTargetSectionId,
      objectId,
      onRef,
      hideLocalMock
		} = inProps || this.props;
		const { object, instancesByPropertyMap, currLocationTitle, selectedPosts } = inState || this.state;
    

    /** @type {ObjectPropertiesPageUIProps} */
		let props = {
      projectId: selectedProjectId,
      onRef,
			isEditable: true,
			types: safeToJS(propertiesTypes?.[subjectName]),
			mapping: safeToJS(propertiesMappings)?.[subjectName],
			sections: safeToJS(propertiesSections?.[subjectName]),
			instances: instancesByPropertyMap,
			onCardLoad,
			onInnerObjectSelect,
			onEditModeChange,
			onDisableChange,
			onCancel,
			onSave: async (updatesMap, _selectedPosts, isSilent) => {
        return await new Promise(resolve => {
          this.setState({ 
            selectedPosts: _selectedPosts,
            selectedPostsDidChange: !_.isEqual(_selectedPosts, selectedPosts)
          }, 
          () => { // will need latest state of selectedPosts to update the form object
            this.saveChanges(updatesMap, isSilent).then(success => resolve(success));
            },
          );
        });
      },
			onDelete: ['employees', 'equipment', 'forms'].includes(subjectType) && this.deleteObject,
      onObjectDelete,
      onObjectCreate,
			filteredProperties: filteredProperties,
			isCreateMode: createObjectMode,
			mode: mode || 'page',
			useCollapsibleSections,
			subjectName,
			popInnerCardQueue,
			setHeaderParams,
			shouldShowPostsSections: subjectName === 'formsInfo',
			tableMethods,
			setCardHeaderParams,
			editMode,
			useMenusScrollsBar,
			addCertButtonTitle:
				subjectName == 'employeesInfo' ? propertiesMessages.documentATraining : propertiesMessages.addEquipmentReview,
			selectedPosts,
      initialGroupId,
      initialSubGroupId,
      showSections: useSections,
      scrollTargetSectionId,
      objectId,
      onPrint,
      hideLocalMock
		};

		let primaryValueTitle;
		if (['card', 'modal'].includes(mode) && instancesByPropertyMap) {
			let primaryValue = instancesByPropertyMap.getNested([primaryPropId]);
			if (primaryValue && primaryValue.propType == propertyTypes.STRING) primaryValueTitle = primaryValue.data;
			else if (propertiesTypes && primaryValue && primaryValue.propType == 'SelectionList')
				propertiesTypes.getNested([subjectName, primaryPropId, 'values'], []).loopEach((i, x) => {
					if (x.id == Object.keys(primaryValue.data)[0]) primaryValueTitle = x.getCementoTitle();
				});
		}

		props.objectTypeName =
			subjectType !== 'forms'
				? propertiesMessages.getNested(['objectsNames', subjectType])
				: reportsMessages.reportTitles[(object || {}).type || formType];
		if (subjectName == 'locationsInfo') props.objectName = Object.values(currLocationTitle).join(' / ');
		else if (primaryValueTitle) props.objectName = primaryValueTitle;
		else if (subjectType === 'forms') {
			let defaultForm = this.getUpdatedFormObject(object);
			if (object) {
				const statusParams = getFormStatusParams(defaultForm);
				props.statusBadgeParams = {
					statusText: statusParams.message ? intl.formatMessage(statusParams.message) : null,
					statusColor: statusParams.color,
				};

				props.isEditable = statusParams.isEditable;
			}

			const formReportDate = _.get(defaultForm, 'reportDate');
			if (formReportDate) {
        props.objectName = moment(formReportDate).utc().format(intl.formatMessage(reportsMessages.reportDateTitle));
        props.formReportDate = formReportDate;
      }
			const formType = _.get(defaultForm, 'type');
      if (formType) {
        props.formType = formType
      }

			props.onPrint = this.displayFormPDF;
		}

		if (subjectType === 'employees') {
			props.extraSections = [
				{
					id: 'presenceLogs',
					title: siteControlMessages.presenceLogs.title,
					renderFunc: this.renderPresenceLogsComponent,
				},
			];

      props.onPrint = this.handleExportPDF('-employeeDetailsForm');
		} else if (subjectType === 'equipment') {
      props.onPrint = this.handleExportPDF('-equipmentDetailsForm');
    }
		return props;
  }

  handleExportPDF = (formTemplateId) => {
    return async () => {
      const { selectedProjectId, viewer, upsertForm, exportFormPDF } = this.props;
      const { object } = this.state;
      const formType = 'objects'
      const formObj = {
        formTemplateId,
        parentId: object?.id,
        type: formType,
      };
      const form = (await upsertForm(selectedProjectId, viewer, formObj, formType))?.form;
      const pdf = await exportFormPDF({
        viewer,
        project: {
          id: selectedProjectId,
        },
        formId: form.id,
        formType,
        isListenerMode: true,
      });
      return { src: pdf.uri, pdfMode: true };
    }
  }

  handleRecalcObjectPropertiesPageUIProps = (prevProps, prevState) => {
    const shouldRecalcObjectPropsPageUIProps = Boolean(
      [
        'subjectType',
        'subjectName',
        'primaryPropId',
        'propertiesTypes',
        'mode',
        'createObjectMode',
        'useMenusScrollsBar',
        'propertiesMappings',
        'propertiesSections',
        'filteredProperties',
        'useCollapsibleSections',
        'editMode',
        'initialGroupId',
        'useSections',
        'scrollTargetSectionId',
        'objectId',
        'hideLocalMock'
      ].some(property => prevProps.isValDiff(this.props, [property])) ||
      [
        'object',
        'instancesByPropertyMap',
        'currLocationTitle',
        'selectedPosts',
      ].some(property => prevState.isValDiff(this.state, [property]))
    );

    if (shouldRecalcObjectPropsPageUIProps)
      this.setState({ objectPropsPageUIProps: this.calcObjectPropertiesPageUIProps(this.props, this.state) })
  }

  getRelevantInstances(nextProps) {
    const { selectedProjectId, objectId, subjectName, primaryPropId, localInstancesByPropertyMap = {}, defaultGroupId } = nextProps || this.props;
    const { objectGroupId: stateObjectGroupId, primaryPropId: statePrimaryPropId } = this.state;
    if (!selectedProjectId || !objectId)
    return {};
    let primaryId = primaryPropId || statePrimaryPropId;
    let primaryValue = null;
    let instancesByPropertyMap = {};
    const fillInstancesMapFunc = (curr) => { 
      if (curr.propId == primaryId) 
        primaryValue = curr.data; 
      instancesByPropertyMap[curr.propId] = curr;
    }
    const lokiFilteredInstances = this.lokiPropertyInstances.cementoFind({ projectId: selectedProjectId, subjectName: subjectName, parentId: objectId });
    (lokiFilteredInstances || []).forEach(fillInstancesMapFunc);
    let objectGroupId = Object.keys(instancesByPropertyMap.getNested(['groups', 'data'], {}))[0];
    if (!objectGroupId && stateObjectGroupId && this.props.objectId == (nextProps||{}).objectId) 
      objectGroupId = stateObjectGroupId;
    if (!objectGroupId && defaultGroupId)
      objectGroupId = defaultGroupId;
      
    return { objectGroupId, instancesByPropertyMap: { ...instancesByPropertyMap, ...localInstancesByPropertyMap }, primaryValue };
  }

  dispatchBasedOnSubjectType = ({ employees, equipment, forms, defaultAction, subjectType = this.props.subjectType, dontExecuteFunc = false }) => {
    const executeAction = funcSingleton(this, `dispatchBasedOnSubjectType-executeAction-${dontExecuteFunc}`, (action) => typeof action === 'function' && !dontExecuteFunc ? action() : action);
    
    switch (subjectType) {
      case ('employees'):
        return executeAction(employees);

      case ('equipment'):
        return executeAction(equipment);

      case ('forms'):
        return executeAction(forms);

      default:
        return executeAction(defaultAction);
    }
  }

  addObject() {
    const {
      upsertEmployee, upsertEquipment, upsertForm,
      selectedProjectId, objectId, viewer,
      objectExtraInfo: { formType } = {}, track
    } = this.props;

    this.dispatchBasedOnSubjectType({
      employees: () => upsertEmployee({ id: objectId }, selectedProjectId),
      equipment: () => upsertEquipment({ id: objectId }, selectedProjectId),
      forms: () => {
        upsertForm(selectedProjectId, viewer, this.getUpdatedFormObject({ id: objectId }), formType, true); // true is for shouldAlertUser
        track('Form created', { projectId: selectedProjectId, formType, formId: objectId });
      }
    });
  }

  updateObject = () => {
    const { upsertForm, viewer, selectedProjectId, track } = this.props;
    const { selectedPostsDidChange, object } = this.state;

    this.dispatchBasedOnSubjectType({
      forms: async () => {
        if (!object || !selectedPostsDidChange)
          return;
        const updatedForm = this.getUpdatedFormObject(object);
        upsertForm(selectedProjectId, viewer, updatedForm, updatedForm.type, true); // true is for shouldAlertUser
        track('Form updated', { projectId: selectedProjectId, formType: updatedForm.type, formId: updatedForm.id });
      }
    });
  }

  getUpdatedFormObject = (form) => {
    const { createdPosts, selectedPosts } = this.state;
    const { objectId, objectExtraInfo: { formType, formTemplateId, formInitStatus, reportDate } = {}, createObjectMode } = this.props;

    let newForm = Object.assign({ id: (form || {}).id || objectId }, form);

    if (formType && !newForm.type)
      newForm.type = formType;

    if (formTemplateId && !newForm.formTemplateId)
      newForm.formTemplateId = formTemplateId;

    if (createObjectMode && formInitStatus && !newForm.status)
      newForm.status = formInitStatus;

    if (createObjectMode && reportDate && !newForm.reportDate)
      newForm.reportDate = reportDate;

    let newPosts = {};
    Object.keys(createdPosts || {}).forEach(postId => 
      _.set(newPosts, [POSTS_OPENED_FROM_REPORT, postId], true)
    );

    Object.keys(selectedPosts || {}).forEach(postId => 
      _.set(newPosts, [POSTS_USER_SELECTED, postId], true)
    );

    newForm.posts = newPosts;

    return newForm;
  }

  displayFormPDF = async () => {
    const { exportFormPDF, project, objectId, objectExtraInfo: { formType } = {}, viewer } = this.props;
    const { object } = this.state;

    let formUri = null;
    const formStatusParams = getFormStatusParams(object);
    if (formStatusParams.isClosed && _.get(object, 'uri')) 
      formUri = object.uri;
    else {
      const operationId = 'object_props_page_export_form_pdf';
      try {
        const formPDFRes = await exportFormPDF({
          viewer,
          project,
          formId: objectId,
          formType: object.type || formType,
          operationId,
          displayLoading: true,
          isListenerMode: true
        });
        if (_.get(formPDFRes, 'uri'))
          formUri = formPDFRes.uri;
      }
      catch (error) {
        console.error("exportFormPDF error: ", error); // Error already reported from exportFormPDF
      }
    }

    return { src: formUri, pdfMode: true };
  }
  
  async deleteObject() {
    const { object } = this.state;
    const { startToast, deleteEmployee, deleteEquipment, deleteForm, selectedProjectId, subjectType, objectId, onDeleteObject, objectExtraInfo: { formType } = {}, track } = this.props;

    const message = this.dispatchBasedOnSubjectType({
      employees: {
        title: propertiesMessages.removeEmployeeTitle,
        message: propertiesMessages.removeEmployee
      },
      equipment: {
        title: propertiesMessages.removeEquipmentTitle,
        message: propertiesMessages.removeEquipment
      }, 
      forms: reportsMessages.removeReport,
    })

    const shouldDelete = await new Promise(resolve => {
      startToast({
        overlay: true, 
        mandatory: true,
        title: message.title, 
        message: message.message,
        actions: [
          { message: systemMessages.yes, onClick: () => resolve(true), color: 'success'},
          { message: systemMessages.no, onClick: () => resolve(false) }
        ]
      });
    });

    if (shouldDelete)
      this.dispatchBasedOnSubjectType({
        employees: () => deleteEmployee(objectId, selectedProjectId),
        equipment: () => deleteEquipment(objectId, selectedProjectId),
        forms: () => {
          const _formType = _.get(object, 'type') || formType
          if (_formType)
            deleteForm(selectedProjectId, object || { id: objectId }, _formType);

          track('Form deleted', { projectId: selectedProjectId, formType: _formType, formId: objectId });
        },
      });

    if (onDeleteObject)
      onDeleteObject(shouldDelete, objectId);

    return shouldDelete;
  } 

  getPropertyString(instance) {
    if (!instance)
      return "";
      
    const { subjectName, propertiesTypes } = this.props;

    let ret = "";
    
    if (instance.propType == propertyTypes.STRING)
      ret = instance.data;
    else if (instance.propType == "SelectionList")
      propertiesTypes.getNested([subjectName, instance.propId, "values"], []).loopEach((i, x) => {
        if (x.id == Object.keys(instance.data)[0])
        ret = x.getCementoTitle();
      });

      return ret;
  }

  async saveChanges(updatesMap, isSilent = false) {
    const {
      selectedProjectId, uploadPropertiesInstances,
      primaryPropId, objectId, subjectName,
      propertiesTypes, onSave, createObjectMode,
      startToast, startLoading, dataWasImported,
      localInstancesByPropertyMap = {}, onLocalInstancesChange,
    } = this.props;
    const { instancesByPropertyMap, primaryValue, selectedPostsDidChange } = this.state;

    let success = true;
    let primaryVal = this.getPropertyString(primaryValue);
    if (updatesMap && Object.values(updatesMap).length > 0 || selectedPostsDidChange) {
      let propertiesInstancesArray = [];
      let localPropertiesInstances = {};
      _.entries(updatesMap).forEach(([propId, data]) => {
        let propType = propertiesTypes.getNested([subjectName, propId, 'type']);
        let currInstance = null;
        let isLocalInstance = Boolean(localInstancesByPropertyMap[propId]);
        const baseNewInstance = { propId, propType, parentId: objectId };
        
        if (!instancesByPropertyMap[propId] && !isLocalInstance) 
          currInstance = Object.assign({}, baseNewInstance);
        else
          currInstance = Object.assign({}, (localInstancesByPropertyMap[propId] || instancesByPropertyMap[propId]), baseNewInstance);

        currInstance.data = data;
        if (currInstance.propId == primaryPropId) {
          primaryVal = this.getPropertyString(currInstance);
        }

        if (dataWasImported) {
          if (!currInstance.id)
            currInstance.id = getNewId();
          localPropertiesInstances[currInstance.id] = currInstance;
        }
        else
          propertiesInstancesArray.push(currInstance);
      })

      if (dataWasImported && !createObjectMode && Object.keys(localPropertiesInstances).length) {
        if (onLocalInstancesChange) onLocalInstancesChange(localPropertiesInstances);
      }

      if (Boolean(propertiesInstancesArray.length) && !dataWasImported) {
        startToast({ title: systemMessages.savingMessage, overlay: true });
        const afterUpload = (didUpload) => {
          if (didUpload) {
            if (!isSilent) 
              startToast({ title: systemMessages.objectSavedSuccessfully, type: 'success', values: { objectName: primaryVal } });
          } else {
            startToast({ title: systemMessages.error, type: 'error'});
            success = false;
          }
        }

        startLoading({ title: systemMessages.savingMessage, overlay: true });
        const payload = await uploadPropertiesInstances(selectedProjectId, propertiesInstancesArray, subjectName);
        afterUpload(payload.success);
      }

      if (createObjectMode)
        this.addObject();
      else
        this.updateObject();
    }

    if (success && onSave)
      onSave();

    return success;
  }

  async handleGetEmployeesPresence() {
    if (!(this.props.subjectType === 'employees' && isSiteControlProject(this.props.selectedProjectId)))
      return;

    const { selectedProjectId: projectId, objectId: id, employeesPresence: _employeesPresence } = this.props;

    let employeesPresenceLogs = {};
    let employeesPresenceSuccess = true;

    try {
      if (_employeesPresence) {
        employeesPresenceLogs = _employeesPresence;
      }
      else {

        // please dont remove, until we add a button to fetch ( in non-siteControl mode - when presence is not passed in props)

        // const startTS = moment().startOf('day').valueOf();
        // const endTS = moment(startTS).add(1, 'day').subtract(1, 'second').valueOf();
        // employeesPresenceLogs = await getEmployeesPresence({
        //   projectId,
        //   startTS,
        //   endTS,
        //   ids: [id]
        // });
      }
    }
    catch (err) {
      employeesPresenceSuccess = false;
    }
    finally {
      const now = Date.now();
      const relevantLogs = _.pickBy(_.get(employeesPresenceLogs, [id, 'log'], {}), (logs, ts) => ts < now);
      this.setState({
        employeesPresenceLogs: relevantLogs,
        employeesPresenceSuccess
      });
    }
  }

  renderPresenceLogsComponent = () => {
    const { employeesPresenceLogs, employeesPresenceSuccess } = this.state;
    let component = null;
    let message = null;
    
    if (_.isEmpty(employeesPresenceLogs)) {
      if (_.isNil(employeesPresenceSuccess))
        message = siteControlMessages.presenceLogs.loading;
      else if (!employeesPresenceSuccess)
        message = siteControlMessages.presenceLogs.loadingFailed;
      else if (employeesPresenceSuccess)
        message = siteControlMessages.presenceLogs.noData;
        
      component = <Text>{message}</Text>;
    }
    else {
      component = <div style={{ padding: theme.padding }}> <EmployeesPresenceLogs smallFontSize={theme.fontSizeH6} bigFontSize={theme.fontSizeH4} logs={employeesPresenceLogs} shouldSquash /></div>;
    }

    return component;
  }

  render() {
    const { objectPropsPageUIProps } = this.state;

    return <ObjectPropertiesPageUI {...objectPropsPageUIProps} />;
  }
}

let styles = {
	postImageSize: 160,
	cardSectionsStyles: {
		padding: `${theme.paddingSize}px ${theme.paddingSize * 2}px`,
		boxShadow: 'rgba(0, 0, 0, 0.08) 0px 1px 20px 0px',
		marginBottom: theme.paddingSize,
		display: 'flex',
		flexDirection: 'column',
	},
};

ObjectPropertiesPage = withStyles(styles)(ObjectPropertiesPage);
ObjectPropertiesPage = injectIntl(ObjectPropertiesPage);
const enhance = compose(
  withRouterHOC,
	connectContext(ProjectContext.Consumer),
	connect(
		state => ({
			rtl: state.app.rtl,
			viewer: state.users.viewer,
		}),
		{
			uploadPropertiesInstances,
			upsertEmployee,
			upsertEquipment,
			deleteEmployee,
			deleteEquipment,
			startToast,
			startLoading,
			hideLoading,
			upsertForm,
			deleteForm,
			exportFormPDF,
			track,
		},
	),
);

export default enhance(ObjectPropertiesPage);
